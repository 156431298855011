<template>
  <div>
    <Modal v-model="addModal" width="70%" footer-hide>
      <p slot="header" class="text-center">创建作业令申请表</p>
      <div>
        <Row :gutter="8" class="m-b-10">
          <i-col span="3">
            <DatePicker type="daterange" placeholder="选择起止日期" size="small" transfer style="width: 100%"
              @on-change="onChangeDates"></DatePicker>
          </i-col>
          <!-- <i-col span="3">
            <Select size="small" placeholder="状态" v-model="query.status" transfer clearable>
              <i-option v-for="item in statusList" :key="'status_' + item.value" :value="item.value">
                {{ item.name }}
              </i-option>
            </Select>
          </i-col> -->
          <i-col span="4">
            <i-input v-model.trim="query.keyword" size="small" placeholder="关键字：编号"></i-input>
          </i-col>
          <i-col span="4">
            <Button type="primary" icon="ios-search" size="small" class="m-r-5" @click="onSearch">搜索</Button>
          </i-col>
        </Row>
        <div class="m-b-10">
          <Button type="success" size="small" :disabled="btnSubmit" @click="onConfirm">确认创建</Button>
        </div>
        <div>
          <Table stripe max-height="620" size="small" :data="tableList" :columns="tableColumns" :loading="tableLoading"
            @on-selection-change="selectionChange"></Table>
          <div class="paging_style">
            <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator show-sizer
              :page-size-opts="pagesizeSetting" @on-page-size-change='onChangePageSize' :current="query.pageNumber"
              @on-change="onChangePageNum"></Page>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { indexMixins } from '@/components/constructionApply/mixins'
import { getWorkdayPage } from '@/api/msp/workdayTable'
import { addTableToManage } from '@/api/msp/workdayManage'

export default {
  props: {
    tId: {
      type: Number,
      required: true
    },
    type: {
      type: Number,
      required: true
    },
    onSuccess: {
      type: Function,
      default: () => { }
    }
  },
  mixins: [indexMixins],
  data () {
    return {
      addModal: false,
      tableId: null,
      assetArray: [],
      stationArray: [],
      // statusList: [],
      query: {
        pageNumber: 1,
        pageSize: 15,
        type: 1,
        startDate: '',
        endDate: '',
        isAddManage: false,
        status: null,
        keyword: ''
      },
      selectedId: [], // 选中项id
      tableList: [],
      total: 0,
      tableLoading: false,
      tableColumns: [
        { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        { title: '编号', align: 'center', minWidth: 100, key: 'code' },
        {
          title: '施工起始日期',
          align: 'center',
          minWidth: 140,
          render: (h, { row }) => {
            return h('span', `${row.startDate} - ${row.endDate}`)
          }
        },
        { title: '任务发起方', align: 'center', minWidth: 120, key: 'companyName' },
        { title: '申请人', align: 'center', minWidth: 120, key: 'userName' },
        { title: '创建时间', align: 'center', minWidth: 120, key: 'createTime' }
      ]
    }
  },
  computed: {
    btnSubmit () {
      return this.selectedId.length === 0
    }
  },
  methods: {
    showModal () {
      this.tableId = this.tId
      this.query.pageNumber = 1
      this.query.startDate = ''
      this.query.endDate = ''
      this.query.type = this.type
      this.query.status = 1
      this.query.keyword = ''
      // this.statusList = this.statusArray.filter(x => x.value !== 0)
      this.getTableData()
      this.addModal = true
    },
    async getTableData () {
      this.tableLoading = true
      const result = await getWorkdayPage(this.query).finally(() => { this.tableLoading = false })
      if (result && !result.errcode) {
        this.tableList = result.list
        this.total = result.totalRow
      }
    },
    onChangeDates (dates) {
      this.query.startDate = dates[0]
      this.query.endDate = dates[1]
    },
    onChangePageNum (curPage) {
      this.selectedId = []
      this.query.pageNumber = curPage
      this.getTableData()
    },
    onChangePageSize (curPageSize) {
      this.query.pageNumber = 1
      this.query.pageSize = curPageSize
      this.getTableData()
    },
    onSearch () {
      this.query.pageNumber = 1
      this.selectedId = []
      this.getTableData()
    },
    selectionChange (selections) {
      this.selectedId = selections.map(x => x.id)
    },
    onConfirm () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认信息无误，并提交？',
        onOk: async () => {
          const postData = {
            type: this.type,
            manageId: this.tId,
            tableIds: JSON.stringify(this.selectedId)
          }
          const result = await addTableToManage(postData)
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.selectedId = []
            this.addModal = false
            this.onSuccess()
          }
        }
      })
    }
  }
}
</script>
