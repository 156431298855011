<template>
  <div>
    <div class="platform-buttom-list">
      <div class="bottom-button m-r-5" @click="goToIndex">
        施工计划表
      </div>
      <div class="bottom-button-active">
        计划详情
      </div>
    </div>
    <div>
      <div class="text-16">
        <span class="p-r-20">{{ taskInfo.workTypeName }}</span>
        <span class="p-r-20">{{ taskInfo.startDate + ' 至 ' + taskInfo.endDate }}</span><span class="p-r-20">{{
          taskInfo.code }}</span>
        <Tag :color="statusInfo.color || 'default'">{{ taskInfo.statusName }}</Tag>
      </div>
      <Divider />
      <!-- <div class="m-b-10">
        <RadioGroup
          v-model="detailType"
          type="button"
          button-style="solid"
          size="small"
          class="m-b-10"
          @on-change="onChangeType"
        >
          <Radio
            v-for="item in detailTypeArray"
            :key="'dType_'+item.value"
            :label="item.value"
          >{{item.name}}</Radio>
        </RadioGroup>
      </div> -->
      <Row :gutter="8" class="m-b-10">
        <i-col span="3">
          <DatePicker type="daterange" placeholder="选择起止日期" size="small" transfer style="width: 100%"
            @on-change="onChangeDates"></DatePicker>
        </i-col>
        <i-col span="3">
          <Select size="small" placeholder="线路" v-model="query.assetId" transfer clearable @on-change="onChangeAsset">
            <i-option v-for="item in assetArray" :key="'aid_' + item.id" :value="item.id">
              {{ item.name }}
            </i-option>
          </Select>
        </i-col>
        <i-col span="3">
          <Select size="small" placeholder="站点" v-model="query.stationId" transfer clearable>
            <i-option v-for="item in stationArray" :key="'aid_' + item.id" :value="item.id">
              {{ item.name }}
            </i-option>
          </Select>
        </i-col>
        <!-- <i-col span="4">
          <i-input
            v-model.trim="query.keyword"
            size="small"
            placeholder="关键字：编号"
          ></i-input>
        </i-col> -->
        <i-col span="6">
          <Button type="primary" icon="ios-search" size="small" class="m-r-5" @click="onSearch">搜索</Button>
          <Button type="success" size="small" v-if="taskInfo.status < 3" @click="onConfirmApply">确认已申请</Button>
        </i-col>
        <i-col span="9" class="text-right">
          <Button type="primary" size="small" class="m-r-5" v-if="taskInfo.status === 0"
            @click="onAddPlan">添加计划表</Button>
          <Button v-if="taskInfo.status === 0 && [1, 3].includes(taskInfo.workType)" type="primary" size="small"
            class="m-r-5" @click="onAddStation">创建站点</Button>
          <Button v-if="taskInfo.status === 0 && taskInfo.workType === 2" type="primary" size="small"
            @click="onCreatePark">创建车场</Button>
        </i-col>
      </Row>
      <div>
        <Table stripe max-height="620" size="small" :data="tableList" :columns="tableColumns" :loading="tableLoading"
          @on-selection-change="selectionChange"></Table>
        <div class="paging_style">
          <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator show-sizer
            :page-size-opts="pagesizeSetting" @on-page-size-change='onChangePageSize' :current="query.pageNumber"
            @on-change="onChangePageNum"></Page>
        </div>
      </div>
    </div>
    <set-worker ref="setWorker" :onSuccess="getTableData" />
    <modify-action-date ref="modifyActionDate" :onSuccess="getTableData" />
    <plan-detail ref="planDetail" :planType="planType" :onSuccess="getTableData" />
    <add-plan ref="addPlan" v-if="showPlanAdd" :tId="id" :type="taskInfo.workType" :onSuccess="planAddSuccess" />
    <modify-info ref="modifyInfo" :onSuccess="getTableData" />
    <modify-park-plan ref="modifyParkPlan" :tableId="id" :optionType="optionType" :isManage="true"
      :onSuccess="planAddSuccess" />
    <add-station v-if="showAddStation" ref="addStation" :assetArray="assetArray" :taskInfo="taskInfo" :isManage="true"
      :onSuccess="planAddSuccess" />
  </div>
</template>

<script>
import { indexMixins } from '@/components/constructionApply/mixins'

import { getWorkdayPage, getWorkdayManageInfo, updateStatus } from '@/api/msp/workdayManage'
import { removeWorkday } from '@/api/msp/workdaySchedule'

import SetWorker from './SetWorker.vue'
import ModifyActionDate from './ModifyActionDate.vue'
import PlanDetail from '@/components/constructionApply/components/PlanDetail.vue'
import AddPlan from './AddPlan.vue'
import ModifyInfo from '@/components/constructionApply/components/ModifyInfo.vue'
import ModifyParkPlan from '@/components/constructionApply/components/ModifyParkPlan.vue'
import AddStation from '@/components/constructionApply/components/AddStation.vue'

export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  mixins: [indexMixins],
  components: {
    SetWorker,
    ModifyActionDate,
    PlanDetail,
    ModifyInfo,
    AddPlan,
    AddStation,
    ModifyParkPlan
  },
  data () {
    return {
      optionType: 1, // 1：新增，2：编辑
      taskInfo: {},
      statusInfo: {},
      assetArray: [],
      stationArray: [],
      showPlanAdd: false,
      query: {
        pageNumber: 1,
        pageSize: 15,
        manageId: null,
        assetId: null,
        startDate: '',
        endDate: '',
        stationId: null,
        supplierCompanyId: null
      },
      tableList: [],
      total: 0,
      tableLoading: false,
      planType: 1,
      typeShowColumns: new Map([
        [1, ['actionDate', 'createCompany', 'assetName', 'stationName', 'position', 'workTime', 'taskCount', 'taskTypes', 'workerNumber', 'workManagerName', 'workContent', 'remark', 'operation']], // 车站计划列表
        [2, ['actionDate', 'park', 'createCompany', 'workTime', 'taskCount', 'workerNumber', 'workManagerName', 'workContent', 'remark', 'operation']], // 车场计划列表
        [3, ['actionDate', 'createCompany', 'assetName', 'stationName', 'position', 'workTime', 'taskCount', 'taskTypes', 'workerNumber', 'workManagerName', 'workContent', 'remark', 'operation']] // 临时计划列表
      ]),
      tableColumns: [],
      selectedItems: [],
      showAddStation: false

    }
  },
  computed: {
    btnSetWorker () {
      return this.selectedItems.length === 0
    }
  },
  mounted () {
    this.query.manageId = this.id
    this.getAssets()
    this.getTaskInfo()
    this.getTableData()
  },
  methods: {
    goToIndex () {
      this.$router.push({ name: 'construction-apply-manage-index' })
    },
    getTableColumns () {
      const defineColumns = {
        selection: { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        actionDate: {
          title: '作业日期',
          align: 'center',
          minWidth: 140,
          key: 'actionDate',
          render: (h, { row }) => {
            return h('span', `${row.actionDate} (${row.dayOfTheWeek})`)
          }
        },
        createCompany: { title: '任务发起方', align: 'center', minWidth: 120, key: 'companyName' },
        assetName: { title: '线路', align: 'center', minWidth: 120, key: 'assetName' },
        stationName: { title: '站点', align: 'center', minWidth: 120, key: 'stationName' },
        park: { title: '车场', align: 'center', minWidth: 120, key: 'stationName' }, // 车场
        position: {
          title: '位置',
          align: 'center',
          minWidth: 120,
          render: (h, { row }) => {
            const html = []
            row.positionList.forEach(item => {
              html.push(h('p', `${item.floor}层 - ${item.positionName}`))
            })
            return h('div', html)
          }
        },
        workTime: { title: '作业时间段', align: 'center', minWidth: 120, key: 'workTime' },
        taskCount: { title: '任务数量', align: 'center', minWidth: 100, key: 'taskCount' },
        taskTypes: { title: '任务类型', align: 'center', minWidth: 120, key: 'taskTypes' },
        workerNumber: { title: '施工人数', align: 'center', minWidth: 100, key: 'workerNumber' },
        supplierCompanyName: { title: '施工单位', align: 'center', minWidth: 120, key: 'supplierCompanyName' },
        workManagerName: {
          title: '带班人员',
          align: 'center',
          children: [
            {
              title: '姓名',
              align: 'center',
              minWidth: 100,
              render: (h, { row }) => {
                return h('span', row.workManagerInfo.name || '/')
              }
            },
            {
              title: '类型',
              align: 'center',
              minWidth: 100,
              render: (h, { row }) => {
                return h('span', row.workManagerInfo.typeName || '/')
              }
            },
            {
              title: '工作时段',
              align: 'center',
              minWidth: 80,
              render: (h, { row }) => {
                return h('span', row.workManagerInfo.workTime || '/')
              }
            },
            {
              title: '优先级',
              align: 'center',
              minWidth: 80,
              render: (h, { row }) => {
                return h('span', row.workManagerInfo.priority || '/')
              }
            },
            {
              title: '距离',
              align: 'center',
              minWidth: 80,
              render: (h, { row }) => {
                return h('span', row.workManagerInfo.distance || '/')
              }
            }
          ]
        },
        workContent: { title: '工作内容', align: 'center', minWidth: 120, key: 'workContent' },
        remark: { title: '备注', align: 'center', minWidth: 120, key: 'remark' },
        operation: {
          title: '操作',
          align: 'center',
          minWidth: 260,
          fixed: 'right',
          render: (h, { row }) => {
            const html = [
              h('a', {
                class: ['m-r-5'],
                on: {
                  click: () => {
                    this.showDetail(row)
                  }
                }
              }, '详情')
            ]
            if (this.taskInfo.status < 3) {
              // 非已申请状态
              html.push(
                // h('a', {
                //   class: ['m-r-5'],
                //   on: {
                //     click: () => {
                //       this.onModifyActionDate(row)
                //     }
                //   }
                // }, '调整作业日期'),
                h('a', {
                  class: ['m-r-5'],
                  on: {
                    click: () => {
                      this.onModifyInfo(row)
                    }
                  }
                }, '基础信息编辑'),
                h('a', {
                  class: ['m-r-5'],
                  on: {
                    click: () => {
                      this.onSetWorker(row)
                    }
                  }
                }, '配置带班人员'),
                h('a', {
                  style: {
                    color: '#ef4f4f'
                  },
                  on: {
                    click: () => {
                      this.onRemove(row)
                    }
                  }
                }, '移除')
              )
            }

            return h('div', html)
          }
        }
      }
      const data = []
      var showColumns = this.typeShowColumns.get(this.planType)
      if (!showColumns || showColumns.length === 0) {
        showColumns = this.typeShowColumns.get(1)
      }
      showColumns.forEach(col => data.push(defineColumns[col]))

      this.tableColumns = data
    },
    async getTableData () {
      this.tableLoading = true
      const result = await getWorkdayPage(this.query).finally(() => { this.tableLoading = false })
      if (result && !result.errcode) {
        this.tableList = result.list
        this.total = result.totalRow
      }
    },
    planAddSuccess () {
      this.getTableData()
      // 调用父级重新加载右侧数据方法
      this.$emit('reloadRight')
    },
    /**
     * 获取详细信息
     */
    async getTaskInfo () {
      const result = await getWorkdayManageInfo({ workdayMangeId: this.id })
      if (result && !result.errcode) {
        this.taskInfo = result
        this.planType = this.taskInfo.workType
        this.statusInfo = this.statusArray.find(x => x.value === this.taskInfo.status)
      }
      this.getTableColumns()
    },
    async getAssets () {
      this.assetArray = await this.getAssetData()
    },
    async onChangeAsset () {
      this.stationArray = await this.getStationData(this.query.assetId)
    },
    onChangeDates (dates) {
      this.query.startDate = dates[0]
      this.query.endDate = dates[1]
    },
    onChangePageNum (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    onChangePageSize (curPageSize) {
      this.query.pageNumber = 1
      this.query.pageSize = curPageSize
      this.getTableData()
    },
    onSearch () {
      this.query.pageNumber = 1
      this.getTableData()
    },
    selectionChange (selections) {
      this.selectedItems = selections.map(x => x.id)
    },
    showDetail (obj) {
      this.$nextTick(() => {
        this.$refs.planDetail.showModal(obj)
      })
    },
    onModifyActionDate (obj) {
      this.$nextTick(() => {
        this.$refs.modifyActionDate.showModal(obj)
      })
    },
    onModifyInfo (obj) {
      this.$nextTick(() => {
        this.$refs.modifyInfo.showModal(obj)
      })
    },
    onRemove (obj) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要移除改项？',
        onOk: async () => {
          const postData = {
            // manageId: this.id,
            workdayId: obj.id
          }
          const result = await removeWorkday(postData)
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.getTableData()
          }
        }
      })
    },
    /**
     * 确认申请
     */
    onConfirmApply () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定信息无误，并提交？',
        onOk: async () => {
          const postData = {
            manageId: this.id,
            status: 3
          }
          const result = await updateStatus(postData)
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.getTaskInfo()
            this.getTableData()
          }
        }
      })
    },
    onSetWorker (obj) {
      this.$nextTick(() => {
        this.$refs.setWorker.showModal(obj)
      })
    },
    onAddPlan () {
      this.showPlanAdd = true
      this.$nextTick(() => {
        this.$refs.addPlan.showModal()
      })
    },
    /**
     * 创建站点
     */
    onAddStation () {
      this.showAddStation = true
      this.$nextTick(() => {
        this.$refs.addStation.showModal()
      })
    },
    /**
    * 创建车场
    */
    onCreatePark () {
      this.optionType = 1
      this.$nextTick(() => {
        this.$refs.modifyParkPlan.showModal()
      })
    }
  }
}
</script>
